import {useHistory, useParams} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import {useGetOrganization} from "../services/ContentManager";
import {CircularProgress, Paper} from "@mui/material";
import Grid from "@mui/material/Grid";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import FilterAndSearchBar from "../components/layout/FilterAndSearchBar";
import Details from "./Dashboard/Details/Details";
import AreaManager from "./Dashboard/AreaManager";
import UserManager from "./Dashboard/UserManager";
import Codes from "./Dashboard/Codes";
import Seats from "./Dashboard/Seats";
import OrganizationRanksManager from "./Dashboard/OrganizationRanksManager";
import OrganizationAchievements from "./Dashboard/OrganizationAchievements";
import Settings from "./Dashboard/Settings/Settings";
import Messages from "./Dashboard/Messages/Messages";
import HeatmapContainer from "./Dashboard/HeatmapContainer";
import { SessionManagement } from "./SessionManagement";
import CouponManagement from "./CouponManagement";
import {Trans} from "react-i18next";
import React, {useContext} from "react";
import {AbilityContext} from "../services/Can";
import {resources, roles} from "../services/ability";
import ShopManagement from "./Vendor/Shop/ShopManagement";
import {OrganizationUsers} from "./Dashboard/OrganizationUsers";
import Offices from "./OrganizationOffices/Offices";
import OfficeArticles from "./OrganizationOffices/OfficeArticles";
import {UserContext} from "./App";
import AwardPositions from "./Dashboard/AwardPositions";
import Statistics from "./Statistics";
import {useGetOrganizationUsers} from "../services/UserDataManager";
import InviteFriends from "./Dashboard/InviteFriends";
import Surveies from "./Dashboard/Surveies";
import { GeneralChecklist } from "./GeneralSettings/GeneralChecklist";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2)
    },
    filterBar: {
        padding: theme.spacing(4),
        paddingBottom: 0
    },
    title: {
        paddingLeft: theme.spacing(2),
        color: theme.palette.primary.main,
        fontWeight: "bold",
        fontSize: 40
    },
    notificationLogo: {
        width: "5vw",
        color: theme.palette.primary.main,
        fontSize: 60,
    },
    initiativeLogo: {
        maxWidth: "80vw",
        maxHeight: "17vh"
    },
}));

const organizationType = {
    PA: 0,
    COMPANY: 1
}

function getFilters(organization, ability, user) {
    let filters = [];
    let id = 0;

    filters.push({
        id,
        filter: "statistics",
        name: <Trans i18nKey="dashboard"/>,
        component: <Statistics/>
    });
    id++;
    filters.push({
        id,
        filter: "details",
        name: <Trans i18nKey="details"/>,
        component: <Details/>
    });
    if (ability.can('read', resources.PRIVATEUSERDATA)) {
        id++;
        filters.push({
            id,
            filter: "sessions",
            name: <Trans i18nKey="sessions"/>,
            component: <SessionManagement/>
        });
        id++;
        filters.push({
            id,
            filter: "users",
            name: <Trans i18nKey="users"/>,
            component: <OrganizationUsers/>
        });
    }
    id++;
    filters.push({
        id,
        filter: "heatmap",
        name: <Trans i18nKey="heatmap"/>,
        component: <HeatmapContainer/>
    });
    id++;
    filters.push({
        id,
        filter: "area",
        name: <Trans i18nKey="initiativeArea"/>,
        component: <AreaManager/>
    });
    id++;
    if (organization.type === organizationType.COMPANY) {
        filters.push({
            id,
            filter: "seats",
            name: <Trans i18nKey="seats"/>,
            component: <Seats/>
        });
        id++;
    }
    filters.push({
        id,
        filter: "codes",
        name: <Trans i18nKey="initiativeCodes"/>,
        component: <Codes/>
    });
    id++;
    filters.push({
        id,
        filter: "inviteFriends",
        name: <Trans i18nKey="inviteFriends"/>,
        component: <InviteFriends/>
    });
    if (user.userType === roles.ADMIN) {
        id++;
        filters.push({
            id,
            filter: "surveies",
            name: <Trans i18nKey="surveies"/>,
            component: <Surveies/>
        });
    }
    id++;
    filters.push({
        id,
        filter: "achievements",
        name: <Trans i18nKey="achievements"/>,
        component: <OrganizationAchievements/>
    });
    id++;
    filters.push({
        id,
        filter: "ranks",
        name: <Trans i18nKey="ranks"/>,
        component: <OrganizationRanksManager/>
    });
    id++;
    filters.push({
        id,
        filter: "managers",
        name: <Trans i18nKey="managers"/>,
        component: <UserManager/>
    });
    id++;
    filters.push({
        id,
        filter: "messages",
        name: <Trans i18nKey="messages"/>,
        component: <Messages/>
    });
    id++;
    filters.push({
        id,
        filter: "settings",
        name: <Trans i18nKey="settings"/>,
        component: <Settings/>
    });
    id++;
    filters.push({
        id,
        filter: "checklist",
        name: <Trans i18nKey="checklist"/>,
        component: <GeneralChecklist/>
    });
    id++;
    filters.push({
        id,
        filter: "coupons",
        name: <Trans i18nKey="coupons"/>,
        component: <CouponManagement/>
    });
    id++;
    filters.push({
        id,
        filter: "shops",
        name: <Trans i18nKey="shops"/>,
        component: <ShopManagement/>
    });
    if (user.userType === roles.ADMIN) {
        id++;
        filters.push({
            id,
            filter: "offices",
            name: <Trans i18nKey="offices"/>,
            component: <Offices oid={organization.id}/>
        });
        id++;
        filters.push({
            id,
            filter: "articles",
            name: <Trans i18nKey="awardsInCatalog"/>,
            component: <OfficeArticles oid={organization.id}/>
        });
    }
        id++;
        filters.push({
            id,
            filter: "awardPositions",
            name: <Trans i18nKey="awardPositions"/>,
            component: <AwardPositions/>
        })

    return filters;
}

export default function Dashboard() {

    let {id, filter} = useParams();
    let {status, organization} = useGetOrganization(id);
    let user = useContext(UserContext);
    let classes = useStyles();
    let history = useHistory();
    const ability = useContext(AbilityContext);
    let filters = getFilters(organization, ability, user);

    useGetOrganizationUsers(id);

    const onFilter = (filterId) => {
        history.push(`/dashboard/${id}/${filters.find(f => f.id === filterId).filter}`)
    }

    if (status === "loading") return <Grid container><CircularProgress/></Grid>

    return (
        <Paper className={classes.page}>
            <Grid container>
                {organization.initiativeLogo ?
                    <Grid item xs={12} align={"center"}>
                        <img className={classes.initiativeLogo} src={organization.initiativeLogo}/>
                    </Grid>
                    :
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        {organization.notificationLogo ?
                            <img className={classes.notificationLogo} src={organization.notificationLogo}/>
                            : <AccountBalanceIcon className={classes.notificationLogo} sx={{fontSize: 60}}/>}
                        <div className={classes.title}>{organization.title}</div>
                    </Grid>
                }
            </Grid>
            <Grid container className={classes.filterBar}>
                <FilterAndSearchBar
                    filters={filters}
                    onFilter={onFilter}
                    selected={filters.find(f => f.filter === filter).id}
                />
            </Grid>
            <div className={classes.page}>
                {filters.find(f => f.filter === filter).component ?? <></>}
            </div>
        </Paper>
    );

}
